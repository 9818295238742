import React, { useEffect, useState } from 'react';
import { AutoComplete } from "antd";
import { DocumentNode, useLazyQuery } from '@apollo/client';
import { Series } from '../../gql/graphql-types';
import { Maybe } from 'graphql/jsutils/Maybe';

interface SelectorProps<T> {
    name: string
    onSelect: (item: Maybe<T>) => unknown,
    onChange?: (value: string) => unknown,
    query: DocumentNode,
    labelSelector: (item: T) => string,
    accessor: (graphqlResponse: any) => T[],
    where: (string: string, map: Record<string, T>) => object
}

function Selector<T>({ name, where, onSelect, onChange = () => {}, query, labelSelector, accessor }: SelectorProps<T>) {
    const [value, setValue] = useState(' ');
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [map, setMap] = useState<Record<string, T>>({});

    const [get, { data, loading, error }] = useLazyQuery(query)


    useEffect(() => {
        get({ variables: { where: where(value, map) } })
    }, [value])

    if (error) {
        console.error(error)
    }

    useEffect(() => {
        if (!loading) {
            const items: T[] = accessor(data) || []

            if (!items.length) {
                setOptions(Object.keys(map).map(value => ({ value })))
            } else {

                const newMap = items.reduce((acc, item: T) => {
                    return Object.assign(acc, { [labelSelector(item)]: item })
                }, map)

                const newOptions = items.map(item => ({ value: labelSelector(item) }))

                setOptions(newOptions)
                setMap(newMap)
            }
        }

    }, [loading])


    return (
        <AutoComplete
            style={{
                minWidth: "200px"
            }}
            placeholder={`Select ${name}...`}
            options={options}
            size='large'
            onFocus={() => {
                setValue('')
            }}
            onChange={(value) => {
                onChange(value)
                setValue(value)
            }}
            onSelect={(value) => {
                onSelect(map[value])
            }}
        />
    )
}


interface SelectModeProps {
    setIsEdit: (value: boolean) => void,
    onSelect: (value: Maybe<Series>) => unknown
}

export default Selector