import React from 'react';
import { Movie } from '../gql/graphql-types';
import * as colors from '@ant-design/colors';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
    color: ${colors.blue[8]};
`

function MovieLink({movie}: {movie?: Movie}) {
    if (!movie) {
        return <StyledLink to={window.location}>Unknown</StyledLink>
    }

    return (
        <StyledLink to={`/movies/${movie.id}`}>
            <em>{movie.title}</em>
        </StyledLink>
    )
}

export default MovieLink