import { gql } from "@apollo/client"

export const GET_MOVIE = gql`
query GetMovie($where: MovieWhereUniqueInput!) {
  movie(where: $where) {
    id
    title
    createdAt
  }
}

`

export const GET_MOVIE_WITH_DETAILS = gql`
query GetMovieWithDetails($where: MovieWhereUniqueInput!) {
  movie(where: $where) {
    id
    title
    description
    createdAt
    scientist {
      id
      name
    }
    scores {
      id
      value
    }
    showings {
      id
      date
      movie {
        id
        title
      }
    }
  }
}

`

export const GET_MOVIES = gql`
query GetMovies {
  movies {
    id
    title
    createdAt
  }
}
`

export const GET_MOVIES_WITH_DETAILS = gql`
query GetMoviesWithDetails {
  movies {
    id
    title
    description
    createdAt
    scientist {
      id
      name
    }
    scores {
      id
      value
    }
    showings {
      id
      date
    }
  }
}
`

export const GET_MOVIES_LIKE = gql`
query GetMoviesLike($where: MovieWhereInput!) {
  movies(where: $where) {
    id
    title
    createdAt
  }
}
`
