import React from 'react';
import { Movie } from '../../gql/graphql-types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { GET_MOVIES_LIKE } from '../../gql/queries/MovieQueries';
import Selector from './Selector';

interface MovieSelectorProps {
    onSelect: (movie: Maybe<Movie>) => unknown
}

function MovieSelector({ onSelect }: MovieSelectorProps) {
    return (
        <Selector
            name="Movie"
            query={GET_MOVIES_LIKE}
            accessor={(res) => {
                return res?.movies || []
            }}
            labelSelector={(m: Movie) => m.title}
            onSelect={onSelect}
            where={value => {
                return { title: { contains: value } }
            }}
        />
    )
}


export default MovieSelector