import styled, { StyledComponent } from "@emotion/styled";
import { Breadcrumb } from "antd";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import BreadcrumbSeparator from "antd/es/breadcrumb/BreadcrumbSeparator";
import { ReactElement, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

export interface PageProps {
    breadcrumbs?: React.ReactElement
    title: React.ReactElement
    children?: ReactNode
}

const PageWrapper = styled.div`
    padding: 1vh 1vw;
`

const Nav = styled.nav`
    display: grid;
    grid: 1fr / min-content;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    gap: 16px;
`

const StyledBreadcrumbItem = styled(BreadcrumbItem)`
    /* padding: 1em; */
    /* line-height: 1; */
    display: inline-block;
    font-weight: 500;
    margin: 0 .2em;
    /* padding: .25em; */
`

const Header = styled.header`
    display: flex;
    justify-content: space-between;
`

function Page ({title, children}: PageProps) {
    const location = useLocation();
    const paths = location.pathname.split('/').filter(Boolean).slice(0, -1)

    const breadcrumbItems: ItemType[] = [{
        title: '🏡',
        href: '/'
        // location: '/',

    }, ...paths.map((p, index) => {
        return {
            title: p,
            href: `/${paths.join("/")}`
        }
    })]

    return (
        <PageWrapper>
            <Header>
                <Breadcrumb items={breadcrumbItems}>
                </Breadcrumb>
                <Breadcrumb>
                    <StyledBreadcrumbItem>
                        <Link to="/ballots">Ballots</Link>
                    </StyledBreadcrumbItem>
                    <StyledBreadcrumbItem>
                        <Link to="/showings">Showings</Link>
                    </StyledBreadcrumbItem>
                    <StyledBreadcrumbItem>
                        <Link to="/movies">Movies</Link>
                    </StyledBreadcrumbItem>
                    <StyledBreadcrumbItem>
                        <Link to="/series">Series</Link>
                    </StyledBreadcrumbItem>
                </Breadcrumb>
            </Header>

            {title}
            {children}
        </PageWrapper>
    )
}

const Title = styled.h1`
    color: black;
`

Page.Title = Title

export default Page