import React from 'react';
import { Series } from '../gql/graphql-types';
import * as colors from '@ant-design/colors';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Maybe } from 'graphql/jsutils/Maybe';

const StyledLink = styled(Link)`
    color: ${colors.green[8]};
`

function SeriesLink({series}: {series: Maybe<Series>}) {
    if (!series) {
        return (
            <StyledLink to={window.location}>Unknown</StyledLink>
        )
    } else {
        return (
            <StyledLink to={`/series/${series.id}`}>
                <em>{series.name}</em>
            </StyledLink>
        )
    }
}

export default SeriesLink