import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Movie, Series } from '../../gql/graphql-types';
import { GET_CURRENT_SERIES } from '../../gql/queries/SeriesQueries';
import { Button } from 'antd';
import MovieSelector from '../../components/selectors/MovieSelector';
import { ADD_MOVIE_TO_SERIES } from '../../gql/mutations/MovieMutations';
import { mean } from 'ramda';
import { selectValue } from '../../utils/ScoreUtils';
import SeriesLink from '../../components/SeriesLink';
import MovieLink from '../../components/MovieLink';
import { selectMovieAvgScore } from '../../components/MovieTable';

function BestMovieSnip() {
    const { data, loading, error } = useQuery(GET_CURRENT_SERIES)
    const currentSeries: Series = data?.series || {};
    const movies: Movie[] = currentSeries.movies || [];

    const objectivelyBestMovie: Movie = movies.reduce((acc, m) => {
        const accScore = selectMovieAvgScore(acc)
        const movieScore = selectMovieAvgScore(m)
    

        if (movieScore > accScore) {
            return m
        } else {
            return acc
        }
    }, movies[0])

    const objectivelyBestScores = objectivelyBestMovie?.scores || [];
    console.log(objectivelyBestMovie)
    const objectivelyBestMovieScore =  mean(objectivelyBestScores.map(selectValue))


    const [isOpen, setIsOpen] = useState(false);

    if (loading) {
        return (
            <span>
              Loading...  
            </span>
        )
    }

    if (error) {
        console.error(error)
    }

    if (Object.keys(currentSeries).length === 0) {
        return null
    }

    if (movies.length === 0) {
        return (
            <>
                <span>
                    There haven't been any movies added to {currentSeries.name} yet. <Button onClick={() => setIsOpen(!isOpen)}>Would you like to add a movie?</Button>
                </span>
                {
                    isOpen && (
                        <AddMovieToSeries seriesId={currentSeries.id} onSet={() => setIsOpen(false)}></AddMovieToSeries>
                    )
                }
            </>
        )
    }

    if (isNaN(objectivelyBestMovieScore)) {
        return (
            <>
                <span>We haven't scored anything yet, though.</span>
            </>
        )
    }


    return (
        <>
            <span>
                The objectively best movie of <SeriesLink series={currentSeries} /> is <MovieLink movie={objectivelyBestMovie}></MovieLink> with a score of {objectivelyBestMovieScore.toFixed(2)}.
            </span>
        </>
    )
}

function AddMovieToSeries ({ seriesId, onSet = () => {}}: { seriesId: number, onSet: () => any }) {
    const client = useApolloClient()
    const [addMovieToSeries, { loading: setLoading, error: setError }] = useMutation(ADD_MOVIE_TO_SERIES)

    return (
        <div>
            <MovieSelector onSelect={async (movie) => {
                if (movie) {
                    await addMovieToSeries({
                        variables: {
                            movieId: movie.id,
                            seriesId
                        }
                    })
                   
                    const updates = await client.refetchQueries({
                        include: [GET_CURRENT_SERIES]
                    });

                    console.log(updates)
                    onSet()
                }
            }} />
        </div>
    )
}



export default BestMovieSnip